import React, { Component, Fragment, PureComponent } from 'react';
import img from 'assets/img/logo192.png';
import img2 from 'assets/img/logo-asia.png';
import { ROUTES } from 'Routes/config/routes';
import { Link } from 'react-router-dom';
import { API_SERVICE_AKADEMIK } from 'config/api';
import { withRouter } from "react-router";
class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            notif: 0,
            listTIDaftar: [],
            listTIDU: [],
            listSKDaftar: [],
            listSKDU: [],
            listDKVDaftar: [],
            listDKVDU: [],
            listMJDaftar: [],
            listMJDU: [],
            listAKDaftar: [],
            listAKDU: [],
            location: null
        };
        this.pollingInterval = null;
        this.closeModal = React.createRef()
        this.openModal = React.createRef()
        this.timer = null;
    }

    fetchData = () => {
        API_SERVICE_AKADEMIK.get('notifikasi').then((res) => {
            this.setState({
                notif: res.data.notifikasiAll,
                listTIDU: res.data.list[0].dataDU,
                listTIDaftar: res.data.list[0].dataDaftar,
                listSKDU: res.data.list[1].dataDU,
                listSKDaftar: res.data.list[1].dataDaftar,
                listDKVDU: res.data.list[2].dataDU,
                listDKVDaftar: res.data.list[2].dataDaftar,
                listMJDU: res.data.list[3].dataDU,
                listMJDaftar: res.data.list[3].dataDaftar,
                listAKDU: res.data.list[4].dataDU,
                listAKDaftar: res.data.list[4].dataDaftar
            })
        })
    };

    startPolling = () => {
        this.fetchData();
        this.pollingInterval = setInterval(this.fetchData, 60000);
    };

    stopPolling = () => {
        clearInterval(this.pollingInterval);
    };

    componentDidMount() {
        this.startPolling();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname != prevProps.location.pathname) {
            this.stopPolling();
            this.startPolling();
        }
        if (prevState.notif < this.state.notif) {
            this.openModal.current.click();
        }
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    // logout 
    redirect() {
        localStorage.clear();
        window.location.replace(ROUTES.App);
    }

    render() {
        return (
            <Fragment>
                <div className='main-header'>
                    <div className='logo-header' data-background-color='blue'>
                        <Link to={ROUTES.Home} className='logo'>
                            <img
                                src={img2}
                                style={{ width: '140px' }}
                                alt='logo-asia'
                                className='navbar-brand' />
                        </Link>
                        <button
                            className='navbar-toggler sidenav-toggler ml-auto'
                            type='button'
                            data-toggle='collapse'
                            data-target='collapse'
                            aria-expanded='false'
                            aria-label='Toggle navigation'>
                            <span className='navbar-toggler-icon'>
                                <i className='icon-menu' />
                            </span>
                        </button>
                        <button className='topbar-toggler more'>
                            <i className='icon-options-vertical' />
                        </button>
                        <div className='nav-toggle'>
                            <button className='btn btn-toggle toggle-sidebar'>
                                <i className='icon-menu' />
                            </button>
                        </div>
                    </div>
                    <nav className='navbar navbar-header navbar-expand-lg' data-background-color='blue2'>
                        <div className='container-fluid'>
                            <ul className='navbar-nav topbar-nav ml-md-auto align-items-center'>
                                <li className="nav-item dropdown hidden-caret">
                                    <a className="nav-link dropdown-toggle" data-toggle="modal" data-target=".bd-example-modal-lg" style={{ cursor: 'pointer' }} ref={this.openModal}>
                                        <i className="fa fa-bell" />
                                        {this.state.notif != 0 ? <span className="notification">{this.state.notif}</span> : null}
                                    </a>
                                </li>

                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        onClick={(e) => {
                                            window.location.reload(e);
                                        }}
                                        style={{
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: 'white',
                                            cursor: 'pointer'
                                        }}>
                                        <i
                                            className='fas fa-sync-alt'
                                            style={{ fontSize: '17px' }} />
                                    </button>
                                </li>

                                <li className='nav-item dropdown hidden-caret'>
                                    <a
                                        className='dropdown-toggle profile-pic'
                                        data-toggle='dropdown'
                                        href='/'
                                        aria-expanded='false'>
                                        <div>
                                            <i
                                                className='fas fa-user-circle'
                                                style={{
                                                    color: 'white',
                                                    fontSize: '24px',
                                                    marginTop: '1px',
                                                    marginLeft: '3px'
                                                }} />
                                            <i
                                                className='fas fa-chevron-down'
                                                style={{
                                                    color: 'white',
                                                    fontSize: '10px',
                                                    position: 'absolute',
                                                    marginTop: '9px',
                                                    marginLeft: '8px'
                                                }} />
                                        </div>
                                    </a>
                                    <ul className='dropdown-menu dropdown-user animated fadeIn'>
                                        <div className='dropdown-user-scroll scrollbar-outer'>
                                            <li>
                                                <div className='user-box'>
                                                    <div className='avatar-lg'>
                                                        <img
                                                            src={img}
                                                            alt='profile'
                                                            className='avatar-img rounded' />
                                                    </div>
                                                    <div className='u-text'>
                                                        <h4>
                                                            {sessionStorage.getItem(
                                                                'nama'
                                                            )}
                                                        </h4>
                                                        <p className='text-muted'>
                                                            FO Center Asia
                                                        </p>
                                                        {/* <a href='profile.html' className='btn btn-xs btn-secondary btn-sm'>View Profile</a> */}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='dropdown-divider' />
                                                <span
                                                    className='dropdown-item logout-button'
                                                    onClick={this.redirect}>
                                                    <i
                                                        className='fas fa-power-off'
                                                        style={{ color: 'red' }} />
                                                    &nbsp;&nbsp;Keluar
                                                </span>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="dropdown-title" style={{ color: 'blueviolet' }}><span className='fa fa-bell pt-3 pb-3'></span>&nbsp;&nbsp;{this.state.notif != 0 ? `Kamu Memiliki ${this.state.notif} Notifikasi Baru` : "Kamu Tidak Memiliki Notifikasi"}</div>
                                <br />
                                {this.state.listTIDaftar.map((list, i) => {
                                    return (
                                        <Link
                                            onClick={() => this.closeModal.current.click()}
                                            to={{
                                                pathname: '/DetailCamaba',
                                                state: {
                                                    no_daftar: list.nopendaftaran,
                                                    id_camaba_encrypt: list.idcamaba
                                                }
                                            }} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        <span className='fa fa-book '></span>&nbsp;&nbsp;ada pendaftaran baru
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Teknik Informatika</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listTIDU.map((list, i) => {
                                    return (
                                        <Link to={'/DaftarUlang?id=' + list.nopendaftaran} onClick={() => this.closeModal.current.click()} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'darkcyan' }}>
                                                        <span className='fa fa-envelope-open'></span>&nbsp;&nbsp;ada daftar ulang masuk
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Teknik Informatika</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listSKDaftar.map((list, i) => {
                                    return (
                                        <Link onClick={() => this.closeModal.current.click()}
                                            to={{
                                                pathname: '/DetailCamaba',
                                                state: {
                                                    no_daftar: list.nopendaftaran,
                                                    id_camaba_encrypt: list.idcamaba
                                                }
                                            }} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        <span className='fa fa-book '></span>&nbsp;&nbsp;ada pendaftaran baru
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Sistem Komputer</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listSKDU.map((list, i) => {
                                    return (
                                        <Link to={'/DaftarUlang?id=' + list.nopendaftaran} onClick={() => this.closeModal.current.click()} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'darkcyan' }}>
                                                        <span className='fa fa-envelope-open'></span>&nbsp;&nbsp;ada daftar ulang masuk
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Sistem Komputer</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listDKVDaftar.map((list, i) => {
                                    return (
                                        <Link onClick={() => this.closeModal.current.click()}
                                            to={{
                                                pathname: '/DetailCamaba',
                                                state: {
                                                    no_daftar: list.nopendaftaran,
                                                    id_camaba_encrypt: list.idcamaba
                                                }
                                            }} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        <span className='fa fa-book '></span>&nbsp;&nbsp;ada pendaftaran baru
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Desain Komunikasi Visual</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listDKVDU.map((list, i) => {
                                    return (
                                        <Link to={'/DaftarUlang?id=' + list.nopendaftaran} onClick={() => this.closeModal.current.click()} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'darkcyan' }}>
                                                        <span className='fa fa-envelope-open'></span>&nbsp;&nbsp;ada daftar ulang masuk
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Desain Komunikasi Visual</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listMJDaftar.map((list, i) => {
                                    return (
                                        <Link onClick={() => this.closeModal.current.click()}
                                            to={{
                                                pathname: '/DetailCamaba',
                                                state: {
                                                    no_daftar: list.nopendaftaran,
                                                    id_camaba_encrypt: list.idcamaba
                                                }
                                            }} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        <span className='fa fa-book '></span>&nbsp;&nbsp;ada pendaftaran baru
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Manajemen</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listMJDU.map((list, i) => {
                                    return (
                                        <Link to={'/DaftarUlang?id=' + list.nopendaftaran} onClick={() => this.closeModal.current.click()} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'darkcyan' }}>
                                                        <span className='fa fa-envelope-open'></span>&nbsp;&nbsp;ada daftar ulang masuk
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Manajemen</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listAKDaftar.map((list, i) => {
                                    return (
                                        <Link onClick={() => this.closeModal.current.click()}
                                            to={{
                                                pathname: '/DetailCamaba',
                                                state: {
                                                    no_daftar: list.nopendaftaran,
                                                    id_camaba_encrypt: list.idcamaba
                                                }
                                            }} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                                                        <span className='fa fa-book '></span>&nbsp;&nbsp;ada pendaftaran baru
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Akuntansi</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                                {this.state.listAKDU.map((list, i) => {
                                    return (
                                        <Link to={'/DaftarUlang?id=' + list.nopendaftaran} onClick={() => this.closeModal.current.click()} key={i}>
                                            <div className="notif-center pl-3 pr-3">
                                                <div className="notif-content">
                                                    <span style={{ fontWeight: 'bold', color: 'darkcyan' }}>
                                                        <span className='fa fa-envelope-open'></span>&nbsp;&nbsp;ada daftar ulang masuk
                                                    </span>
                                                    <br />
                                                    <span className="time">{list.nopendaftaran} - {list.nama} - Akuntansi</span>
                                                </div>
                                                <hr />
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Header);

import { lazy } from 'react';

export const NotFound = lazy(() => import('components/NotFound'));
export const Home = lazy(() => import('pages/Home'));
export const Auth = lazy(() => import('pages/auth/Auth'));
export const LoadingOut = lazy(() => import('pages/auth/LoadingOut'));
export const LoadingAuth = lazy(() => import('pages/auth/LoadingAuth'));
export const SettingPlotBiaya = lazy(() => import('pages/pengaturan/SettingPlotBiaya'));
export const SettingTahunAjaran = lazy(() => import('pages/pengaturan/SettingTahunAjaran/index'));
export const SettingPeriode = lazy(() => import('pages/pengaturan/SettingPeriode/index'));
export const KomponenBiaya = lazy(() => import('pages/pengaturan/KomponenBiaya/index'));
export const SettingBayar = lazy(() => import('pages/pengaturan/SettingBayar/index'));
export const SettingPotongan = lazy(() => import('pages/pengaturan/SettingPotongan/index'));
export const SettingKonversi = lazy(() => import('pages/pengaturan/SettingKonversi/index'));
export const SubJalur = lazy(() => import('pages/pengaturan/SubJalur/index'));
export const DaftarAwal = lazy(() => import('pages/TambahPMB'));
export const DaftarUlang = lazy(() => import('pages/pendaftaran/DaftarUlang/index'));
export const DataCamaba = lazy(() => import('pages/pendaftaran/DataCamaba/index'));
export const DetailCamaba = lazy(() => import('pages/pendaftaran/DataCamaba/DetailCamaba/index'));
export const Mahasiswa = lazy(() => import('pages/pembayaran/Mhs/index'));
export const ListMahasiswa = lazy(() => import('pages/mahasiswa/ListMahasiswa'));
export const VirtualAccount = lazy(() => import('pages/pembayaran/Virtual/index'));
export const GenerateVA = lazy(() => import('pages/pengaturan/GenerateVA/index'));
export const RekapitulasiHarian = lazy(() => import('pages/rekap/RekapitulasiHarian/index'));
export const CuciKasir = lazy(() => import('pages/rekap/CuciKasir/index'));
export const EditBTK = lazy(() => import('pages/rekap/EditBTK/index'));
export const CancelBTK = lazy(() => import('pages/rekap/CancelBTK/index'));
export const Pendaftaran = lazy(() => import('pages/pendaftaran/Rekap/index'));
export const Voucher = lazy(()=>import('pages/voucher/index'))
export const FullDU  = lazy(()=>import('pages/pengaturan/FullDU/index'))
export const GenerateNim  = lazy(()=>import('pages/mahasiswa/GenerateNim'))

export const ROUTES = {
    App: '/',
    LoadingAuth: '/loadingAuth',
    Home: '/home',
    SettingPlotBiaya: '/pengaturanPlotBiaya',
    SettingTahunAjaran: '/pengaturanTA',
    SettingPeriode: '/pengaturanPeriode',
    KomponenBiaya: '/KomponenBiaya',
    SettingBayar: '/pengaturanBayar',
    SettingPotongan: '/pengaturanPotongan',
    SettingKonversi: '/pengaturanKonversi',
    SubJalur: '/SubJalurProdi',
    DaftarAwal: '/DaftarAwal',
    DaftarUlang: '/DaftarUlang',
    DataCamaba: '/DataCamaba',
    DetailCamaba: '/DetailCamaba',
    Mahasiswa: '/Mahasiswa',
    ListMahasiswa: '/ListMahasiswa',
    VirtualAccount: '/VirtualAccount',
    GenerateVA:'/GenerateVA',
    RekapitulasiHarian:'/RekapitulasiHarian',
    CuciKasir:'/CuciKasir',
    EditBTK:'/EditBTK',
    CancelBTK:'/CancelBTK',
    Pendaftaran:'/Pendaftaran',
    Voucher:'/Voucher',
    FullDU:'/FullDU',
    GenerateNim:'/GenerateNim',
};

import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <div className='loadingwall'>
                <div id='loader'>
                    <div id='shadow' />
                    <div id='box' />
                </div>
            </div>
        );
    }
}

export default Loading;

import axios from 'axios';

/**
 * Public API connection
 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const API_ASTOR = axios.create({
    baseURL: process.env.REACT_APP_API_ASTOR
});

export const API_SERVICE_AKADEMIK = axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE_AKADEMIK
});

export const API_MHS_KRS = axios.create({
    baseURL: process.env.REACT_APP_API_MHS_KRS
});

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'Routes/config/routes';
class Sidebar extends Component {
  render() {
    return (
      <div className='sidebar sidebar-style-2'>
        <div className='sidebar-wrapper scrollbar scrollbar-inner'>
          <div className='sidebar-content'>
            <ul className='nav nav-primary'>
              <li className='nav-item'>
                <Link to='/home'>
                  <i className='fas fa-home' />
                  <p>Dashboard</p>
                </Link>
              </li>

              <li className='nav-item'>
                <a data-toggle='collapse' href='#Setting'>
                  <i className='fas fa-cog' />
                  <p>Pengaturan</p>
                  <span className='caret' />
                </a>
                <div className='collapse' id='Setting'>
                  <ul className='nav nav-collapse'>
                    <li>
                      <Link className='sub-item' to={ROUTES.SettingTahunAjaran}>
                        <span className='sub-item'>Tahun Ajaran</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.SubJalur}>
                        <span className='sub-item'>Sub Prodi</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.SettingPeriode}>
                        <span className='sub-item'>Periode</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.SettingPotongan}>
                        <span className='sub-item'>Potongan</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.KomponenBiaya}>
                        <span className='sub-item'>Komponen Biaya</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.SettingBayar}>
                        <span className='sub-item'>Bayar</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link className='sub-item' to={ROUTES.SettingKonversi}>
                        <span className='sub-item'>Range Konversi</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link className='sub-item' to={ROUTES.GenerateVA}>
                        <span className='sub-item'>Virtual Account Generate</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.FullDU}>
                        <span className='sub-item'>Biaya Full DU</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className='nav-item'>
                <a data-toggle='collapse' href='#Daftar'>
                  <i className='fas fa-registered' />
                  <p>Pendaftaran</p>
                  <span className='caret' />
                </a>
                <div className='collapse' id='Daftar'>
                  <ul className='nav nav-collapse'>
                    <li>
                      <Link className='sub-item' to={ROUTES.DaftarAwal}>
                        <span className='sub-item'>Daftar Awal</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.DaftarUlang}>
                        <span className='sub-item'>Daftar Ulang</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.DataCamaba}>
                        <span className='sub-item'>Data Camaba</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.Pendaftaran}>
                        <span className='sub-item'>Rekap</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className='nav-item'>
                <a data-toggle='collapse' href='#mahasiswa'>
                  <i className='fas fa-user' />
                  <p>Mahasiswa</p>
                  <span className='caret' />
                </a>
                <div className='collapse' id='mahasiswa'>
                  <ul className='nav nav-collapse'>
                    <li>
                      <Link className='sub-item' to={ROUTES.ListMahasiswa}>
                        <span className='sub-item'>List Mahasiswa</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.GenerateNim}>
                        <span className='sub-item'>Generate NIM</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className='nav-item'>
                <a data-toggle='collapse' href='#Bayar'>
                  <i className='fas fa-money-bill-alt' />
                  <p>Pembayaran</p>
                  <span className='caret' />
                </a>
                <div className='collapse' id='Bayar'>
                  <ul className='nav nav-collapse'>
                    <li>
                      <Link className='sub-item' to={ROUTES.Mahasiswa}>
                        <span className='sub-item'>Mahasiswa</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.VirtualAccount}>
                        <span className='sub-item'>Set Up Biaya Virtual Account</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className='nav-item'>
                <a data-toggle='collapse' href='#Rekap'>
                  <i className='fas fa-database' />
                  <p>Rekap</p>
                  <span className='caret' />
                </a>
                <div className='collapse' id='Rekap'>
                  <ul className='nav nav-collapse'>
                    <li>
                      <Link className='sub-item' to={ROUTES.RekapitulasiHarian}>
                        <span className='sub-item'>Rekapitulasi Harian Penerimaan</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.CuciKasir}>
                        <span className='sub-item'>Cuci Kasir</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.EditBTK}>
                        <span className='sub-item'>Edit BTK</span>
                      </Link>
                    </li>
                    <li>
                      <Link className='sub-item' to={ROUTES.CancelBTK}>
                        <span className='sub-item'>Cancel BTK</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className='nav-item'>
                <Link to='/voucher'>
                  <i className='fas fa-ticket-alt' />
                  <p>Voucher</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* End Sidebar */}
      </div>
    );
  }
}

export default Sidebar;

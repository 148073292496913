import Loading from 'components/Loading';
import React, { Suspense, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaries from 'utils/ErrorBoundaries';
import ConfigAdmin from 'config/configAdmin';
import { Auth, LoadingAuth, ROUTES } from './config/routes';
import { errorBoundaryFallback } from 'config/constant';

class MainRoutes extends Component {
    render() {
        return (
            <ErrorBoundary FallbackComponent={ErrorBoundaries} onReset={errorBoundaryFallback}>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path={ROUTES.App} exact={true} component={Auth} />
                        <Route path={ROUTES.LoadingAuth} exact={true} component={LoadingAuth} />
                        {/* authed route */}
                        <ConfigAdmin />
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        );
    }
}

export default MainRoutes;

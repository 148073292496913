import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';
import { Voucher,CuciKasir, DaftarAwal, DaftarUlang, DataCamaba, DetailCamaba, EditBTK, GenerateVA, Home, KomponenBiaya, ListMahasiswa, Mahasiswa, RekapitulasiHarian, ROUTES, SettingBayar, SettingKonversi, SettingPeriode, SettingPlotBiaya, SettingPotongan, SettingTahunAjaran, SubJalur, VirtualAccount, CancelBTK, Pendaftaran, FullDU, GenerateNim } from './config/routes';


class AdminRoutes extends Component {
    render() {
        return (
            <Fragment>
                <Route path={ROUTES.Home} exact={true} component={Home} />
                <Route path={ROUTES.SettingPlotBiaya} exact={true} component={SettingPlotBiaya} />
                <Route path={ROUTES.SettingTahunAjaran} exact={true} component={SettingTahunAjaran} />
                <Route path={ROUTES.SettingPeriode} exact={true} component={SettingPeriode} />
                <Route path={ROUTES.KomponenBiaya} exact={true} component={KomponenBiaya} />
                <Route path={ROUTES.SettingBayar} exact={true} component={SettingBayar} />
                <Route path={ROUTES.SettingPotongan} exact={true} component={SettingPotongan} />
                <Route path={ROUTES.SettingKonversi} exact={true} component={SettingKonversi} />
                <Route path={ROUTES.SubJalur} exact={true} component={SubJalur} />
                <Route path={ROUTES.DaftarAwal} exact={true} component={DaftarAwal} />
                <Route path={ROUTES.DaftarUlang} exact={true} component={DaftarUlang} />
                <Route path={ROUTES.DataCamaba} exact={true} component={DataCamaba} />
                <Route path={ROUTES.DetailCamaba} exact={true} component={DetailCamaba} />
                <Route path={ROUTES.Mahasiswa} exact={true} component={Mahasiswa} />
                <Route path={ROUTES.ListMahasiswa} exact={true} component={ListMahasiswa} />
                <Route path={ROUTES.VirtualAccount} exact={true} component={VirtualAccount} />
                <Route path={ROUTES.GenerateVA} exact={true} component={GenerateVA} />
                <Route path={ROUTES.RekapitulasiHarian} exact={true} component={RekapitulasiHarian} />
                <Route path={ROUTES.CuciKasir} exact={true} component={CuciKasir} />
                <Route path={ROUTES.EditBTK} exact={true} component={EditBTK} />
                <Route path={ROUTES.CancelBTK} exact={true} component={CancelBTK} />
                <Route path={ROUTES.Pendaftaran}  exact={true} component={Pendaftaran} />
                <Route path={ROUTES.Voucher} exact={true} component={Voucher}></Route>
                <Route path={ROUTES.FullDU} exact={true} component={FullDU}></Route>
                <Route path={ROUTES.GenerateNim} exact={true} component={GenerateNim}></Route>
            </Fragment>
        );
    }
}

export default AdminRoutes;

import swal from 'sweetalert';
import PropTypes from 'prop-types';

const ErrorBoundaries = ({ error, resetErrorBoundary }) => {
    return swal({
        icon: 'error',
        title: 'Something went wrong',
        text: error.message,
        buttons: true
    }).then(() => {
        resetErrorBoundary();
    });
};

ErrorBoundaries.propTypes = {
    error: PropTypes.string.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired
};

export default ErrorBoundaries;

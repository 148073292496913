export const infoAsia = [
    { value: '1', label: 'Sosialisasi' },
    { value: '2', label: 'Dosen / Karyawan' },
    { value: '3', label: 'Mahasiswa Asia' },
    { value: '4', label: 'Brosur' },
    { value: '5', label: 'Instagram' },
    { value: '6', label: 'Youtube' },
    { value: '7', label: 'Tiktok' },
    { value: '8', label: 'Koran' },
    { value: '9', label: 'Radio' },
    { value: '10', label: 'Website' },
    { value: '0', label: 'Lain-lain' }
];

export const errorBoundaryFallback = () => {
    return window.location.reload();
};

export const RECAPTCHA_SITEKEY = '6Lei7XobAAAAAD9VZXNMVdsZVx2Cb8f7wkwK-w7p';

export const IMG = {
    LOGIN_BG: './assets/img/bglogin.jpg'
};

export const status = {
    aktif: 'aktif',
    nonAktif: 'nonAktif'
};

export const methodType = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export const URL_SERVICE_AKADEMIK = process.env.REACT_APP_API_SERVICE_AKADEMIK;

export const currencyInputConfig = {
    prefix: 'Rp. ',
    groupSeparator: '.',
    decimalSeparator: ',',
    required: true
};

export const KEY_API_MHS_KRS = '?v=YjFsMldrbFBTbFpZWWxScWVXZGlUa3cwY2twamFVbFVWM1pqUjFaSlNHSjZkM2hZVEhSblIzaHNUbU5OYTJNNVkwRmljbEZhYzFCcVFUUjZkVmhDT0E9PQ==';
export const KEY_API_ASTOR = '?z=N5DoPQvfGnV7m/jLpswgSJMpZ1ezSiw0';

import React, { Component } from 'react';
import API_ASTOR from 'pages/axios/Api_astor';
import Key_astor from 'pages/axios/Key_astor';

class Footer extends Component {
    state = {
        version: '',
        tahun: ''
    };

    componentDidMount() {
        this.getVersion();
    }

    getVersion = async () => {
        await API_ASTOR.get('data/version/app/fo/v' + Key_astor.baseURL).then(
            (Response) =>
                this.setState({
                    version: Response.data[0].version,
                    tahun: Response.data[0].tahun
                })
        );
    }

    render() {
        return (
            <footer className='footer'>
                <div className='container-fluid'>
                    <nav className='pull-left'>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <span className='nav-link'>
                                    Sistem Informasi FO Institut Asia
                                </span>
                            </li>
                        </ul>
                    </nav>
                    <div className='copyright ml-auto'>
                        V-{this.state.version} - Developed by UPT-SI
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

import React, { Component } from 'react';
import Header from 'components/Admin/Header';
import Sidebar from 'components/Admin/Sidebar';
import Footer from 'components/Admin/Footer';
import AdminRoutes from 'Routes/AdminRoutes';

class ConfigAdmin extends Component {
    componentDidMount() {
        this.getAuth();
        this.checkLoginSession();
    }

    getAuth = async () => {
        var authId = localStorage.getItem('icmb');
        var nim = localStorage.getItem('nim');

        if (authId === undefined || nim === undefined) {
            window.location.replace('/');
        }
    };

    checkLoginSession = () => {
        var authExpiry = localStorage.getItem('auth_expired_date');
        const now = new Date();

        if (now.getTime() > authExpiry) {
            window.location.replace('/');
        }
    };

    render() {
        window.scroll(0, 0)
        return (
            <div className='wrapper'>
                <Header />
                <Sidebar />
                <div className='main-panel'>
                    <AdminRoutes />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default ConfigAdmin;
